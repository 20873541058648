import React, {useState} from 'react';
import {Message} from "../table";
import {Dropdown, IconButton, Modal, Popover, Tooltip, Whisper} from "rsuite";
import clsx from "clsx";
import {Menu} from "@rsuite/icons";
import MessageContext from "./MessageContext";

interface MessageRowProps {
  message: Message;
  isSub?: boolean;
}
export const MessageRow: React.FC<MessageRowProps> = ({ message, isSub }) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [modalImg, setModalImg] = useState('');
  const [contextModalOpen, setContextModalOpen] = useState(false)
  const handleClose = () => setModalOpen(false);

  const TT = (id: string, label: string) => (
    <Tooltip>
      <div className={clsx('flex-col')}>
        <div>
          <span>{label}:</span>
        </div>
        <div>
          <span>{id}</span>
        </div>
      </div>
    </Tooltip>
  );

  const re = /\.(png|jpg|jpeg)$/
  const urls = message.Attachments?.split(',').filter(s => s.match(re)) ?? [];

  const renderMenu = ({ onClose, left, top, className }: any, ref: React.RefCallback<HTMLElement>) => {
    const handleSelect = (eventKey: any) => {
      onClose();
      switch (eventKey as number) {
        case 3:
          setContextModalOpen(true)
      }
    };
    return (
      <Popover ref={ref} className={className} style={{ left, top }} full>
        <Dropdown.Menu onSelect={handleSelect}>
          <Dropdown.Item eventKey={3}>View In Context</Dropdown.Item>
          <Dropdown.Item eventKey={4}>Go To Channel Logs</Dropdown.Item>
        </Dropdown.Menu>
      </Popover>
    );
  };


  return (
    <>
      <div className={clsx('flex-col', 'h-full', 'max-w-full')}>
        <div className={clsx('flex', 'flex-row', 'justify-between')}>
          {/* Header */}
          <div>
            <div>
              <Whisper speaker={TT(message.AuthorID ?? '', 'Author ID')} placement={'top'} trigger={'hover'} enterable>
                <span className={clsx('text-lg')}>{message.Author}</span>
              </Whisper>
              <Whisper speaker={TT(message.ChannelID ?? '', 'Channel ID')} placement={'top'} trigger={'hover'} enterable>
                <span className={clsx('text-sm', 'text-gray-500', 'ml-2')}>{message.ChannelName}</span>
              </Whisper>
            </div>
            <div className={clsx('text-sm', 'text-gray-400')}>
              {message.Date?.toLocaleString()}
            </div>
          </div>
          <div>
            {!isSub && (
              <Whisper speaker={renderMenu} placement={'left'} trigger={'click'}>
                <IconButton appearance={'subtle'} icon={<Menu />} />
              </Whisper>
            )}
          </div>
        </div>
        <div className={clsx('mt-2', 'break-all')}>
          {message.Content}
        </div>
        {urls.length > 0 && (
          <div className={clsx('mt-3', 'flex', 'flex-row', 'space-x-2')}>
            {urls.map((s, i) => (
              <div className={clsx('cursor-pointer')} onClick={() => {
                setModalOpen(true);
                setModalImg(s)
              }}>
                <img alt={`message-attachment-${i}`} src={s} className={clsx('max-h-96')}/>
              </div>
            ))}
          </div>
        )}
      </div>
      <Modal size={'full'} open={modalOpen} onClose={handleClose}>
        <Modal.Header>{modalImg}</Modal.Header>
        <Modal.Body>
          <div className={clsx('flex align-middle justify-center')}>
            <img src={modalImg}/>
          </div>
        </Modal.Body>
      </Modal>
      <Modal size={'full'} open={contextModalOpen} onClose={() => setContextModalOpen(false)}>
        <Modal.Header>{message.Author} in {message.ChannelName}</Modal.Header>
        <Modal.Body>
          <MessageContext channelId={message.ChannelID ?? ''} date={message.Date ?? new Date()} />
        </Modal.Body>
      </Modal>
    </>
  )
}
