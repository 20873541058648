import React from 'react';
import {Message} from "../table";
import {List} from "rsuite";
import clsx from "clsx";
import {MessageRow} from "./Message";

interface MessageListProps {
  messages: Message[];
  isSub?: boolean;
}

const MessageList: React.FC<MessageListProps> = (props) => {
  return (
    <List>
      {props.messages.map((value, index) => (
        <List.Item index={index} itemID={`message-${index}`} key={`message-${index}`} className={clsx('hover:bg-gray-900', props.isSub ? 'px-2' : '')}>
          <MessageRow message={value} isSub={props.isSub} />
        </List.Item>
      ))}
    </List>
  );
}

export default MessageList;
