import React, {useContext, useState} from 'react';
import {
  Loader,
  Message as RMessage,
  useToaster,
} from "rsuite";
import clsx from "clsx";
import {FilterForm} from "./components/FilterForm";
import MessageList from "./components/MessageList";
import config from "./config";
import {TokenContext} from "./App";

export type Message = {
  AuthorID?: string;
  ChannelID?: string;
  ChannelName?: string;
  Author?: string;
  Date?: Date;
  Content?: string;
  Attachments?: string;
  Reactions?: string;
}

const LogTable = () => {
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState<Message[]>([]);
  const [error, setError] = useState<string | undefined>();
  const { token } = useContext(TokenContext);
  const toaster = useToaster();


  const loadData = (values: any) => {
    setLoading(true)

    let url = new URL(`${config.API_URL}/api/v1/messages`)

    const order = values['sort'] ?? 'asc'
    url.searchParams.append('order', order)

    for (const k in values) {
      const val = values[k]
      if (val) {
        switch (k) {
          case "before":
          case "after":
            const dateVal = val as Date
            url.searchParams.append(k, dateVal.getTime() + "")
            break;
          case "between":
            const a = val[0] as Date
            const b = val[1] as Date

            url.searchParams.append('after', a.getTime() + "")
            url.searchParams.append('before', b.getTime() + "")

            break;
          default:
            url.searchParams.append(k, val)
        }
      }
    }

    fetch(url.toString(), {
      headers: { 'Authorization': `Bearer ${token}`}
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText)
        }

        return res.json()
      })
      .then(res => {
        setMessages(res.messages?.map((m: any): Message => {
          return {
            AuthorID: m.author_id,
            ChannelID: m.channel_id,
            ChannelName: m.channel_name,
            Author: m.author,
            Date: new Date(m.date),
            Content: m.content,
            Attachments: m.attachments,
            Reactions: m.reactions,
          }
        }))
      })
      .catch((e: Error) => setError(e.message))
      .finally(() => setLoading(false));
  }

  if (error) {
    const message = (
      <RMessage showIcon type={'error'}>
        {error}
      </RMessage>
    );

    toaster.push(message, {
      placement: 'topCenter',
    })
    return (
      <></>
    )
  }

  return (
    <>
      <div className={clsx('mb-4')}>
        <FilterForm loadData={loadData} />
      </div>
      {loading && (
        <div className={clsx(`flex justify-center items-center w-full pt-18`)}>
          <Loader size={'lg'} />
        </div>
      )}
      {(!loading && messages?.length > 0) && (
        <MessageList messages={messages} />
      )}
    </>
  )
}


export default LogTable;
