import React, {useContext, useEffect} from 'react';
import {Container, Header, Content, Sidebar, Nav, Navbar, Sidenav, Avatar} from 'rsuite'
import {ArrowLeftLine, ArrowRightLine, Gear, Dashboard} from '@rsuite/icons'
import LogTable from "./table";
import Icon from './icon.png';
import {Route, Routes, useNavigate} from "react-router-dom";
import LoginCallbackScreen from "./screens/LoginCallback";
import LoginScreen from "./screens/Login";
import clsx from "clsx";
import config from "./config";

const headerStyles: React.CSSProperties = {
  padding: 18,
  fontSize: 16,
  background: '#34c3ff',
  color: ' #fff',
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  display: 'flex',
  alignItems: 'center'
};

const NavToggle = ({expand, onChange}: { expand: boolean, onChange: any }) => {
  const { signOut } = useContext(TokenContext);

  return (
    <Navbar appearance="subtle" className="nav-toggle">
      <Navbar.Body>
        <Nav>
          <Nav.Menu
            placement="topStart"
            trigger="click"
            icon={<Gear/>}
          >
            <Nav.Item onClick={signOut}>Sign out</Nav.Item>
          </Nav.Menu>
        </Nav>

        <Nav pullRight>
          <Nav.Item onClick={onChange} style={{width: 56, textAlign: 'center'}}>
            {expand ? <ArrowLeftLine/> : <ArrowRightLine/>}
          </Nav.Item>
        </Nav>
      </Navbar.Body>
    </Navbar>
  );
};

const Page = () => {
  const [expand, setExpand] = React.useState(true);
  const { token } = useContext(TokenContext);
  const nav = useNavigate();

  if (!token) {
    nav('/login');
    return (<></>);
  }

  return (
    <div className="show-fake-browser sidebar-page">
      <Container>
        <Sidebar
          className={clsx('h-screen', 'flex', 'flex-col', 'fixed')}
          width={expand ? 260 : 76}
          collapsible
        >
          <Sidenav.Header>
            <div style={headerStyles}>
              <Avatar src={Icon}/>
              {expand && (
                <span style={{marginLeft: 12}}>Aiba's Log Explorer</span>
              )}
            </div>
          </Sidenav.Header>
          <div className={clsx('h-full', 'flex', 'flex-col', 'justify-between')}>
            <Sidenav expanded={expand} defaultOpenKeys={['3']} appearance="subtle">
              <Sidenav.Body>
                <Nav>
                  <Nav.Item eventKey="1" active icon={<Dashboard/>}>
                    Dashboard
                  </Nav.Item>
                </Nav>
              </Sidenav.Body>
            </Sidenav>
            <NavToggle expand={expand} onChange={() => setExpand(!expand)}/>
          </div>
        </Sidebar>

        <Container
          style={{ marginLeft: expand ? 260 : 76}}
          className={clsx('transition-all', 'ease-in')}
        >
          <Header>
            <Container style={{margin: '0.6rem 1.6rem'}}>
              <h2>Log Explorer</h2>
            </Container>
          </Header>
          <Content>
            <Container style={{margin: '1.6rem'}}>
              <LogTable/>
            </Container>
          </Content>
        </Container>
      </Container>
    </div>
  );
};

export const TokenContext = React.createContext({
  token: "",
  setToken: (_: string) => {},
  signOut: () => {},
});

function App() {
  const [token, setToken] = React.useState<string>("");
  const [init, setInit] = React.useState(false)
  const nav = useNavigate();

  useEffect(() => {
    const t = localStorage.getItem('token') ?? '';
    if (t === '') {
      setToken('');
      setInit(true);
      return;
    }

    fetch(`${config.API_URL}/api/v1/me`, {
      method: 'GET',
      headers: {'Authorization': `Bearer ${t}`}
    })
      .then(r => {
        if (!r.ok) {
          throw new Error("Not ok :(");
        }

        return r;
      })
      .then(() => setToken(t))
      .catch(clearToken)
      .finally(() => setInit(true));
  }, [])

  useEffect(() => {
    if (init) {
      const t = localStorage.getItem('token') ?? '';
      if (t !== token) {
        localStorage.setItem("token", token)
      }
    }
  }, [token])

  const clearToken = () => {
    localStorage.setItem("token", '');
    setToken('');
    nav('/login');
  }

  return (
    <TokenContext.Provider value={{ token: token, setToken: setToken, signOut: clearToken }}>
      <Routes>
        <Route path={"/"} element={<Page />} />
        <Route path={"/login"} element={<LoginScreen />} />
        <Route path={"/login/callback"} element={<LoginCallbackScreen />} />
      </Routes>
    </TokenContext.Provider>
  );
}

export default App;
