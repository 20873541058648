import React from 'react';
import {Button, DateRangePicker, Form, ButtonToolbar, RadioGroup, Radio, DatePicker, DatePickerProps} from "rsuite";
import clsx from "clsx";
import {ValueType} from "rsuite/Radio";

const DateTimePicker = (props: DatePickerProps) => (
  <DatePicker
    format={"yyyy-MM-dd HH:mm:ss"}
    {...props}
  />
);

export interface FilterFormProps {
  loadData: (values: any) => void
}

type UserType = 'none' | 'id' | 'name';

type ContentType = 'none' | 'includes';

export const FilterForm: React.FC<FilterFormProps> = (props) => {
  const [formValue, setFormValue] = React.useState({});
  const [content, setContent] = React.useState('none');
  const handleContentChange = (value: ValueType, event: React.SyntheticEvent) => {
    setContent(value as string)
  }

  const [date, setDate] = React.useState('none');
  const handleDateChange = (value: ValueType, event: React.SyntheticEvent) => {
    setDate(value as string)
  }

  const [user, setUser] = React.useState<UserType>('none');
  const handleUserChange = (value: ValueType, event: React.SyntheticEvent) => {
    setUser(value as UserType)
  }

  const [channel, setChannel] = React.useState<UserType>('none');
  const handleChannelChange = (value: ValueType, event: React.SyntheticEvent) => {
    setChannel(value as UserType)
  }

  const [sort, setSort] = React.useState<string>('asc');
  const handleSortChange = (value: ValueType, event: React.SyntheticEvent) => {
    setSort(value as string)
    setFormValue({...formValue, sort: value as string});
  }

  const resetUser = () => {
    setFormValue({
      ...formValue,
      user_id: null,
      user_query: null,
    })
  }

  const resetChannel = () => {
    setFormValue({
      ...formValue,
      channel_id: null,
      channel_query: null,
    })
  }

  const resetContent = () => {
    setFormValue({
      ...formValue,
      content: null,
    })
  }

  return (
    <Form className={clsx('flex', 'flex-col')} onSubmit={() => props.loadData(formValue)} formValue={formValue} onChange={v => setFormValue(v)}>
      <RadioGroup appearance={'picker'} inline value={content} onChange={handleContentChange} className={'mb-3'} onChangeCapture={resetContent}>
        <span className={clsx('flex', 'items-center', 'mx-2', 'w-24')}>Content Filter:</span>
        <Radio value={'none'}>None</Radio>
        <Radio value={'id'}>Includes</Radio>
      </RadioGroup>
      {content !== 'none' && (
        <div className={clsx('pl-2', 'mb-4')}>
          <Form.Group>
            <Form.ControlLabel>Includes:</Form.ControlLabel>
            <Form.Control name={'content'}/>
            <Form.HelpText tooltip>Message data includes.</Form.HelpText>
          </Form.Group>
        </div>
      )}
      {/* User Filter */}
      <RadioGroup appearance={'picker'} inline value={user} onChange={handleUserChange} className={'mb-3'} onChangeCapture={resetUser}>
        <span className={clsx('flex', 'items-center', 'mx-2', 'w-24')}>User Filter:</span>
        <Radio value={'none'}>None</Radio>
        <Radio value={'id'}>ID</Radio>
        <Radio value={'name'}>Name</Radio>
      </RadioGroup>
      {user !== 'none' && (
        <div className={clsx('pl-2', 'mb-4')}>
          {user === 'id' && (
            <Form.Group>
              <Form.ControlLabel>User ID:</Form.ControlLabel>
              <Form.Control name={'user_id'}/>
              <Form.HelpText tooltip>The discord user id.</Form.HelpText>
            </Form.Group>
          )}
          {user === 'name' && (
            <Form.Group>
              <Form.ControlLabel>User Name:</Form.ControlLabel>
              <Form.Control name={'user_query'}/>
              <Form.HelpText tooltip>The discord name.</Form.HelpText>
            </Form.Group>
          )}
        </div>
      )}

      {/* Channel Filter */}
      <RadioGroup appearance={'picker'} inline value={channel} onChange={handleChannelChange} className={'mb-3'} onChangeCapture={resetChannel}>
        <span className={clsx('flex', 'items-center', 'mx-2', 'w-24')}>Channel Filter:</span>
        <Radio value={'none'}>None</Radio>
        <Radio value={'id'}>ID</Radio>
        <Radio value={'name'}>Name</Radio>
      </RadioGroup>
      {channel !== 'none' && (
        <div className={clsx('pl-2', 'mb-4')}>
          {channel === 'id' && (
            <Form.Group>
              <Form.ControlLabel>Channel ID:</Form.ControlLabel>
              <Form.Control name={'channel_id'}/>
              <Form.HelpText tooltip>The discord channel id.</Form.HelpText>
            </Form.Group>
          )}
          {channel === 'name' && (
            <Form.Group>
              <Form.ControlLabel>Channel Name:</Form.ControlLabel>
              <Form.Control name={'channel_query'}/>
              <Form.HelpText tooltip>The discord name.</Form.HelpText>
            </Form.Group>
          )}
        </div>
      )}

      {/* Date Filter */}

      <RadioGroup appearance={'picker'} inline value={date} onChange={handleDateChange} className={clsx('mb-3')}>
        <span className={clsx('flex', 'items-center', 'mx-2', 'w-24')}>Date Filter:</span>
        <Radio value={'none'}>None</Radio>
        <Radio value={'before'}>Before</Radio>
        <Radio value={'after'}>After</Radio>
        <Radio value={'between'}>Between</Radio>
      </RadioGroup>
      {date !== 'none' && (
        <div className={clsx('pl-2', 'mb-4')}>
          {date === 'between' && (
            <Form.Group controlId="between">
              <Form.ControlLabel>Date Range:</Form.ControlLabel>
              <Form.Control name="between" accepter={DateRangePicker} style={{width: 300}}/>
            </Form.Group>
          )}
          {date === 'before' && (
            <Form.Group controlId="before">
              <Form.ControlLabel>Before:</Form.ControlLabel>
              <Form.Control name="before" accepter={DateTimePicker} style={{width: 300}}/>
            </Form.Group>
          )}
          {date === 'after' && (
            <Form.Group controlId="after">
              <Form.ControlLabel>After:</Form.ControlLabel>
              <Form.Control name="after" accepter={DateTimePicker} style={{width: 300}}/>
            </Form.Group>
          )}
        </div>
      )}

      {/* Order By */}

      <RadioGroup appearance={'picker'} inline value={sort} onChange={handleSortChange} className={clsx('mb-3')}>
        <span className={clsx('flex', 'items-center', 'mx-2', 'w-24')}>Sort:</span>
        <Radio value={'asc'}>Old → New</Radio>
        <Radio value={'desc'}>New → Old</Radio>
      </RadioGroup>

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" type={'submit'}>Submit</Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  )
}
