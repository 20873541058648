import React, {useContext, useEffect, useState} from 'react';
import {useNavigate, useSearchParams} from "react-router-dom";
import {Loader, Notification, useToaster} from "rsuite";
import {TokenContext} from "../App";
import clsx from "clsx";
import config from "../config";

const LoginCallbackScreen = () => {
  const [error, setError] = useState<string | undefined>()
  const [params,] = useSearchParams()
  const { setToken } = useContext(TokenContext)
  const nav = useNavigate();
  const toaster = useToaster();

  useEffect(() => {
    const error = params.get("error")
    if (error) {
      setError(error);
      return;
    }

    const code = params.get("code")

    fetch(`${config.API_URL}/api/v1/authorize`, {
      method: 'POST',
      headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        code: code
      })
    }).then(res => {
      if (!res.ok) {
        throw new Error('invalid login');
      }
      return res.json();
    }).then(res => {
      localStorage.setItem("token", res.access_token)
      setToken(res.access_token);
      nav('/');
    }).catch(e => {
      setError(e);
    });
  }, [nav, params, setToken])

  if (error) {
    toaster.push(
      <Notification type={'error'} header={'error'} closable>
        Invalid Login. You may be unauthorized to use this application.
      </Notification>,
      {
        placement: 'topEnd'
      }
    );
    nav('/login');
    return <></>;
  }

  return (
    <div className={clsx('w-full', 'h-screen', 'flex', 'flex-col', 'justify-center', 'items-center')}>
      <h1 className={clsx('pb-8')}>Logging in...</h1>
      <Loader size={'lg'}/>
    </div>
  );
}

export default LoginCallbackScreen;