import React, {useContext, useEffect, useState} from 'react';
import {Message} from "../table";
import clsx from "clsx";
import {Loader} from "rsuite";
import MessageList from "./MessageList";
import config from "../config";
import {TokenContext} from "../App";

interface MessageContextProps {
  channelId: string;
  date: Date;
}

const MessageContext: React.FC<MessageContextProps> = ({channelId, date}) => {
  const [messages, setMessages] = useState<Message[]>([]);
  const [error, setError] = useState<string | undefined>();
  const [loaded, setLoaded] = useState(false);
  const { token } =  useContext(TokenContext);

  useEffect(() => {
    fetch(`${config.API_URL}/api/v1/channel/${channelId}/messages/around/${date.getTime()}`, {
      headers: { 'Authorization': `Bearer ${token}`}
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error(res.statusText)
        }

        return res.json()
      })
      .then(res => {
        setMessages(res.messages?.map((m: any): Message => {
          return {
            AuthorID: m.author_id,
            ChannelID: m.channel_id,
            ChannelName: m.channel_name,
            Author: m.author,
            Date: new Date(m.date),
            Content: m.content,
            Attachments: m.attachments,
            Reactions: m.reactions,
          }
        }))
      })
      .catch((e: Error) => setError(e.message))
      .finally(() => setLoaded(true));
  }, [])

  if (!loaded) {
    return (
      <div className={clsx(`flex justify-center items-center w-full pt-18`)}>
        <Loader size={'lg'}/>
      </div>
    )
  }

  if (error) {
    return (
      <div className={clsx(`flex justify-center items-center w-full pt-18`)}>
        {error}
      </div>
    )
  }

  return (
    <MessageList messages={messages} isSub />
  );
}

export default MessageContext;
