import React, {useContext} from 'react';
import {TokenContext} from "../App";
import {useNavigate} from "react-router-dom";
import clsx from "clsx";
import {Button} from "rsuite";
import Icon from '../icon-bw.png';
import config from "../config";

const LoginScreen = () => {
  const { token } = useContext(TokenContext);
  const nav = useNavigate();

  const url = encodeURIComponent(config.APP_URL ?? '') + "/login/callback";

  if (token) {
    nav('/');
    return <></>;
  }

  return (
    <div className={clsx('w-full', 'flex-row', 'flex', 'justify-center', 'align-middle', 'mt-12')}>
      <div className={clsx('w-7/12', 'p-8', 'flex', 'flex-col', 'bg-gray-900', 'rounded-md', 'justify-center', 'items-center')}>
        <img className={clsx('w-1/3', 'rounded-2xl', 'mb-4')} src={Icon}/>
        <h1>Welcome to Log Explorer</h1>
        <Button style={{ backgroundColor: '#7289da' }} className={clsx('my-8')} href={`https://discord.com/api/oauth2/authorize?client_id=998846716801458177&redirect_uri=${url}&response_type=code&scope=identify`}>Login with Discord</Button>
      </div>
    </div>
  );
}

export default LoginScreen;
